// Helpers
import numeral from 'numeral';
import {
  useProjectsFiltersState,
  provinceIdToLabelMap
} from 'src/store/ProjectsFiltersStore';
import localizer from 'src/localization/localizer';

// Types
import { ProjectsFilterKeyType } from '../ProjectsFilters';

// Styling
import styled, { StyledProps, css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow 1;
  font-size: 0.875rem;
`;

const ValuesDivider = styled.span`
  margin: 0 4px;
  color: ${({ theme }) => theme.gray60};
`;

const RangeDisplayed = styled.div`
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  margin: 0 6px;
`;

const ChosenFilterValue = styled.div<StyledProps<{ zIndex?: number }>>`
  position: relative;
  height: 30px;
  min-width: 30px;
  border-radius 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.beigeBg30};
  color: ${({ theme }) => theme.black};
  z-index: ${({ zIndex }) => zIndex};
`;

const Bridge = styled.div<StyledProps<{ zIndex?: number }>>`
  width: 12px;
  height 4px;
  border-radius: 2px 4px 4px 2px;
  background-color: ${({ theme }) => theme.beigeBg30};
  margin: 0 -4px 0 -1px;
  z-index: ${({ zIndex }) => zIndex};
  border-left: none;
`;

const SelectLocation = styled.span`
  color: ${({ theme }) => theme.gray60};
`;

const SelectedLocation = styled(SelectLocation)`
  color: ${({ theme }) => theme.black};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const SearchRadius = styled.span`
  white-space: nowrap;
  flex-shrink: 0;
`;

const FilterButtonContent = ({
  filterKey,
  children
}: {
  filterKey: ProjectsFilterKeyType;
  children: any;
}) => {
  const { ProjectsFiltersState } = useProjectsFiltersState();
  const { filterValues } = ProjectsFiltersState;

  const filterObject = filterValues[filterKey];

  let content;

  switch (filterKey) {
    case 'price':
    case 'surface':
      content = (
        <Wrapper>
          {filterKey === 'price' && filterObject.unit}
          <RangeDisplayed>
            {`${numeral(filterObject.values.min).format()}`}
            <ValuesDivider>-</ValuesDivider>
            {`${numeral(filterObject.values.max).format()}`}
          </RangeDisplayed>
          {filterKey === 'surface' && filterObject.unit}
          {children}
        </Wrapper>
      );
      break;

    case 'numberOfBedrooms':
    case 'numberOfBathrooms':
      const itemWithPlus = filterObject.options[filterObject.displayMax - 1];
      const itemWithPlusIndex = filterObject.values.findIndex(
        (value: number) => value === itemWithPlus
      );
      content = (
        <Wrapper>
          {filterObject.values
            .slice(
              0,
              itemWithPlusIndex > -1
                ? itemWithPlusIndex + 1
                : filterObject.displayMax
            )
            .map((value: number, idx: number, array: number[]) => {
              let label = value.toString();
              if (value === itemWithPlus) {
                label += ' +';
              }

              if (idx === 0 || idx === array.length - 1) {
                return (
                  <>
                    <ChosenFilterValue key={value} zIndex={array.length - idx}>
                      {label}
                    </ChosenFilterValue>
                    {array.length > 1 && idx === 0 && (
                      <Bridge zIndex={array.length} key={`bridge-${value}`} />
                    )}
                  </>
                );
              } else {
                return <div />;
              }
            })}

          {children}
        </Wrapper>
      );
      break;

    case 'location':
      content = (
        <Wrapper>
          {filterObject.searchByProvince && filterObject.provinceValues ? (
            <>
              {filterObject.provinceValues.length === 0 ? (
                <div>{localizer.projectsFilters.selectProvinces}</div>
              ) : (
                <div>
                  {
                    localizer.belgiumProvinces[
                      provinceIdToLabelMap[
                        filterObject.provinceValues[0]
                      ] as keyof typeof localizer.belgiumProvinces
                    ]
                  }
                  {filterObject.provinceValues.length > 1 ? (
                    <b>
                      {' '}
                      {localizer.formatString(
                        localizer.projectsFilters.moreProvinces,
                        filterObject.provinceValues.includes(
                          'Zeeuws-Vlaanderen'
                        )
                          ? filterObject.provinceValues.length - 4
                          : filterObject.provinceValues.length - 1
                      )}
                    </b>
                  ) : null}
                </div>
              )}
            </>
          ) : (
            <>
              {filterObject.place ? (
                <SelectedLocation>{filterObject.place}</SelectedLocation>
              ) : (
                <SelectLocation>
                  {localizer.projectsFilters.selectLocation}
                </SelectLocation>
              )}{' '}
              {filterObject.values > 0 && filterObject.place ? (
                <SearchRadius>
                  (+{filterObject.values} {filterObject.unit})
                </SearchRadius>
              ) : null}
            </>
          )}

          {children}
        </Wrapper>
      );
      break;

    default:
      content = null;
  }

  return content;
};

export default FilterButtonContent;
