// Styling
import styled from 'styled-components';

const PageWrapper = styled.div`
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  z-index: 100;
  min-height: 100dvh;
  overflow: auto;
`;

const CityPage = () => {
  return <PageWrapper>City Page</PageWrapper>;
};

export default CityPage;
