import React, { useReducer, createContext, useContext } from 'react';

import isEqual from 'lodash.isequal';
import filtersConfig from 'src/configs/ProjectsFilters';
import { Marketplace } from '@prompto-api';

//import { addQueryParams, fetchSettingsFromURL } from 'src/helpers/utils';

export const ProjectsFiltersContext = createContext({
  projects: []
});

// const stringifyFilter = (key, value) => {
//   let stringified = '';

//   switch (key) {
//     case 'location':
//       stringified += `|coords^${
//         value.coords ? `${value.coords.lat}_${value.coords.lng}` : 'none'
//       }`;
//       stringified += `|limits^${value.limits.max}`;
//       stringified += `|place^${value.place ?? 'none'}`;
//       stringified += `|provinceValues^${value.provinceValues.join(',')}`;
//       stringified += `|searchByProvince^${value.searchByProvince}`;
//       stringified += `|unit^${value.unit}`;
//       stringified += `|values^${value.values}`;
//       break;
//     case 'numberOfBathrooms':
//     case 'numberOfBedrooms':
//       stringified += `${value.values.join(',')}`;
//       break;
//     case 'price':
//     case 'surface':
//       stringified += `${value.values.min}_${value.values.max}|${value.limits.min}_${value.limits.max}|${value.unit}`;
//       break;
//     default:
//   }

//   return stringified;
// };

// const stringifyFilterValues = (filterValues) => {
//   let stringified = '';
//   Object.entries(filterValues).forEach(
//     ([key, value]) => (stringified += `<${key}~${stringifyFilter(key, value)}>`)
//   );
//   return stringified;
// };

// const stringifyFiltersState = (state) => {
//   const { availableFilters, filterValues, selectedFilters, wizardCompleted } =
//     state;

//   // Abbreviations for filters state keys:
//   // af - available filters
//   // fv - filter values
//   // sf - selected filters
//   // wc - wizard completed

//   let stringified = `[wc=${wizardCompleted}]`;

//   if (availableFilters && availableFilters.length > 0) {
//     stringified += `[af=${availableFilters
//       .map((filter) => filter.key)
//       .join('|')}]`;
//   }

//   if (selectedFilters && selectedFilters.length > 0) {
//     stringified += `[sf=${selectedFilters
//       .filter((filter) => filter.selected)
//       .map((filter) => filter.key)
//       .join('|')}]`;
//   }

//   if (filterValues && Object.keys(filterValues).length > 0) {
//     stringified += `[fv=${stringifyFilterValues(filterValues)}]`;
//   }

//   return stringified;
// };

// const persistStateInLocalStorage = (state, shouldStoreStateInUrl = true) => {
//   const clearedState = {};
//   Object.keys(state).forEach((key) => {
//     if (!['mapInstance', 'forceShowWizard'].includes(key)) {
//       clearedState[key] = state[key];
//     }
//   });

//   const { shareFilter } = fetchSettingsFromURL();

//   if (shareFilter && shouldStoreStateInUrl) {
//     const stringifiedFiltersState = stringifyFiltersState(clearedState);
//     addQueryParams([{ filter: stringifiedFiltersState }]);
//   }

//   const content = JSON.stringify(clearedState);
//   localStorage.setItem(filtersConfig.sessionStorageKey, content);
// };

export const BelgiumProvinces = [
  'West-Vlaanderen',
  'Oost-Vlaanderen',
  'Antwerpen',
  'Limburg',
  'Vlaams-Brabant',
  'Bruxelles',
  'Brabant Wallon',
  'Hainaut',
  'Namur',
  'Liège',
  'Luxembourg'
];

export const NetherlandsProvinces = ['Zeeuws-Vlaanderen'];

export const NetherlandsSubProvinces = ['Sluis', 'Hulst', 'Terneuzen'];

export const provinceIdToLabelMap = {
  'West-Vlaanderen': 'WestFlanders',
  'Oost-Vlaanderen': 'EastFlanders',
  Antwerpen: 'Antwerp',
  Limburg: 'Limburg',
  'Vlaams-Brabant': 'FlemishBrabant',
  Bruxelles: 'Brussels',
  'Brabant Wallon': 'WalloonBrabant',
  Hainaut: 'Hainaut',
  Namur: 'Namur',
  Liège: 'Liege',
  Luxembourg: 'Luxemburg',
  'Zeeuws-Vlaanderen': 'DutchFlanders'
};

export const initialFilters = {
  price: {
    limits: { min: 0, max: 10000000 },
    values: { min: 0, max: 10000000 },
    unit: '€'
  },

  surface: {
    limits: { min: 0, max: 10000 },
    values: { min: 0, max: 10000 },
    unit: 'm²'
  },

  location: {
    searchByProvince: false,
    place: '',
    limits: { max: 100.0 },
    values: 20,
    unit: 'Km',
    provinceOptions: [...BelgiumProvinces, ...NetherlandsProvinces],
    provinceValues: []
  },
  numberOfBedrooms: {
    options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    displayMax: 4
  },

  numberOfBathrooms: {
    options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    displayMax: 3
  }
};

const ProjectsFiltersStore = (props) => {
  const getFilters = () => {
    const filtersFromLocalStorage = localStorage?.getItem('initialFilters');
    return filtersFromLocalStorage
      ? { ...initialFilters, ...JSON.parse(filtersFromLocalStorage) }
      : initialFilters;
  };
  const [ProjectsFiltersState, ProjectsFiltersDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'setInitialFilterValues':
          return {
            ...state,
            filterValues: action.payload.filterValues,
            temporaryFilterValues: action.payload.filterValues,
            selectedFilters: action.payload.selectedFilters,
            availableFilters: state.availableFilters.filter(
              (filter) =>
                action.payload.selectedFilters.find((x) => x.key === filter.key)
                  .selected === false
            ),
            wizardCompleted: action.payload.wizardCompleted ?? true
          };

        case 'setSharedFiltersState':
          const nextStateOnSaveSharedFiltersState = {
            ...state,
            wizardCompleted: action.payload.wizardCompleted,
            selectedFilters: state.selectedFilters.map((filter) => {
              if (action.payload.selectedFilters.includes(filter.key)) {
                return {
                  ...filter,
                  selected: true
                };
              } else {
                return filter;
              }
            }),
            availableFilters: state.allFilters.filter((filter) =>
              action.payload.availableFilters.includes(filter.key)
            ),
            filterValues: {
              ...state.filterValues,
              surface: action.payload.filterValues.surface,
              price: action.payload.filterValues.price,
              numberOfBedrooms: {
                ...state.filterValues.numberOfBedrooms,
                ...action.payload.filterValues.numberOfBedrooms
              },
              numberOfBathrooms: {
                ...state.filterValues.numberOfBathrooms,
                ...action.payload.filterValues.numberOfBathrooms
              },
              location: {
                ...state.filterValues.location,
                ...action.payload.filterValues.locationFilter
              }
            }
          };

          //persistStateInLocalStorage(nextStateOnSaveSharedFiltersState, false);

          return nextStateOnSaveSharedFiltersState;
        case 'setFiltersFromSearchPreference': {
          // availableFilters: filtersConfig.allFilters,
          // selectedFilters: [],
          // filterValues: getFilters(),

          let newAvailableFilters = [...state.availableFilters];
          let newSelectedFilters = [...state.selectedFilters];

          let newFilterValues = {
            ...state.filterValues
          };

          if (action.payload.maxPrice) {
            newFilterValues.price = {
              ...state.filterValues.price,
              values: {
                min: action.payload.minPrice,
                max: action.payload.maxPrice
              }
            };

            const availableFilter = newAvailableFilters.find(
              (x) => x.key === 'price'
            );
            if (availableFilter) {
              newAvailableFilters = newAvailableFilters.filter(
                (filter) => filter.key !== 'price'
              );
              newSelectedFilters.push({ ...availableFilter, selected: true });
            }
          }

          if (action.payload.maxSurface) {
            newFilterValues.surface = {
              ...state.filterValues.surface,
              values: {
                min: action.payload.minSurface,
                max: action.payload.maxSurface
              }
            };

            const availableFilter = newAvailableFilters.find(
              (x) => x.key === 'surface'
            );
            if (availableFilter) {
              newAvailableFilters = newAvailableFilters.filter(
                (filter) => filter.key !== 'surface'
              );
              newSelectedFilters.push({ ...availableFilter, selected: true });
            }
          }

          return {
            ...state,
            filterValues: newFilterValues,
            availableFilters: newAvailableFilters,
            selectedFilters: newSelectedFilters
          };
        }
        case 'setTargetLocation': {
          if (action.payload) {
            let newAvailableFilters = [...state.availableFilters];
            let newSelectedFilters = [...state.selectedFilters];

            const availableFilter = newAvailableFilters.find(
              (x) => x.key === 'location'
            );
            if (availableFilter) {
              newAvailableFilters = newAvailableFilters.filter(
                (filter) => filter.key !== 'location'
              );
              newSelectedFilters.push({ ...availableFilter, selected: true });
            }

            const nextStateOnSetTargetLocation = {
              ...state,
              filterValues: {
                ...state.filterValues,
                location: {
                  ...state.filterValues.location,
                  ...action.payload
                }
              },
              availableFilters: newAvailableFilters,
              selectedFilters: newSelectedFilters,
              wizardCompleted: false,
              forceShowWizard: true,
              currentWizardStep: 0
            };

            //persistStateInLocalStorage(nextStateOnSetTargetLocation);

            return nextStateOnSetTargetLocation;
          } else {
            const nextStateOnSetEmptyTargetLocation = {
              ...state,
              wizardCompleted: true,
              forceShowWizard: false,
              currentWizardStep: 0
            };

            //persistStateInLocalStorage(nextStateOnSetEmptyTargetLocation);

            return nextStateOnSetEmptyTargetLocation;
          }
        }

        case 'onFilterSelected':
          const newAvailableFilters = state.availableFilters.filter(
            (filter) => filter.key !== action.payload
          );

          const newSelectedFilters = [...state.selectedFilters];

          state.availableFilters.forEach((filter) => {
            if (filter.key === action.payload) {
              newSelectedFilters.push({
                ...filter,
                selected: true,
                focused: true
              });
            }
          });

          const nextStateOnSelectFilter = {
            ...state,
            availableFilters: newAvailableFilters,
            selectedFilters: newSelectedFilters
          };

          //persistStateInLocalStorage(nextStateOnSelectFilter);
          return nextStateOnSelectFilter;

        case 'onFilterDeselected':
          const nextSelectedFilters = state.selectedFilters.filter((filter) => {
            if (filter.key !== action.payload) {
              return true;
            }
          });

          const nextAvailableFilters = state.allFilters.filter((filter) => {
            const isStillAvailable =
              state.availableFilters.findIndex((f) => f.key === filter.key) >
              -1;
            const shouldBecomeAvailable = filter.key === action.payload;
            return isStillAvailable || shouldBecomeAvailable;
          });

          const nextStateOnDeselectFilter = {
            ...state,
            availableFilters: nextAvailableFilters,
            selectedFilters: nextSelectedFilters,
            filterValues: {
              ...state.filterValues,
              [action.payload]: getFilters()[action.payload]
            }
          };

          //persistStateInLocalStorage(nextStateOnDeselectFilter);
          return nextStateOnDeselectFilter;

        case 'onUpdateTemporaryFilterValues':
          const updatedTemporaryFilterValues = {
            ...state.temporaryFilterValues,
            [action.payload.filterKey]: action.payload.filterObject
          };

          return {
            ...state,
            temporaryFilterValues: updatedTemporaryFilterValues
          };

        case 'onUpdateFilterValues':
          const updatedFilterValues = {
            ...state.filterValues,
            [action.payload.filterKey]: action.payload.filterObject
          };

          // If user changes filter value, the filter get selected
          // (applicable for wizard)
          const updatedSelectedFilters = state.selectedFilters.map((filter) => {
            if (filter.key === action.payload.filterKey) {
              return {
                ...filter,
                selected: true
              };
            } else {
              return filter;
            }
          });

          const nextStateOnUpdateFilters = {
            ...state,
            filterValues: updatedFilterValues,
            temporaryFilterValues: updatedFilterValues
          };

          if (!isEqual(state.selectedFilters, updatedSelectedFilters)) {
            nextStateOnUpdateFilters.selectedFilters = updatedSelectedFilters;
            nextStateOnUpdateFilters.availableFilters =
              state.availableFilters.filter(
                (filter) => filter.key !== action.payload.filterKey
              );
          }

          //persistStateInLocalStorage(nextStateOnUpdateFilters);
          return nextStateOnUpdateFilters;

        case 'onApplyFilters':
          const nextStateOnApplyFilters = {
            ...state,
            filterValues: { ...state.temporaryFilterValues }
          };

          //persistStateInLocalStorage(nextStateOnApplyFilters);
          return nextStateOnApplyFilters;

        case 'clearAllFilters':
          const nextStateOnClearAllFilters = {
            ...state,
            filterValues: getFilters(),
            temporaryFilterValues: getFilters(),
            availableFilters: filtersConfig.allFilters,
            selectedFilters: [],
            vaultFilter: null
          };

          //persistStateInLocalStorage(nextStateOnClearAllFilters);
          return nextStateOnClearAllFilters;

        case 'changeWizardPage':
          return {
            ...state,
            currentWizardStep: action.payload
          };

        case 'completeWizard':
          return {
            ...state,
            wizardCompleted: true,
            forceShowWizard: false
          };

        case 'setMapInstance':
          return {
            ...state,
            mapInstance: action.payload
          };

        case 'onCloseMobileFiltersModal':
          return {
            ...state,
            ...action.payload
          };

        case 'updateFromUnitsFilter':
          const updatedUnitFilterValues = {
            ...state.filterValues
          };

          // Only update selected filter
          updatedUnitFilterValues[action.payload.filterKey].values =
            action.payload.filterObject[action.payload.filterKey].values;

          // If user changes filter value, the filter get selected
          const updatedSelectedUnitFilters = state.selectedFilters.map(
            (filter) => {
              if (filter.key === action.payload.filterKey) {
                return {
                  ...filter,
                  selected: true
                };
              } else {
                return filter;
              }
            }
          );

          const nextStateOnUpdateUnitFilters = {
            ...state,
            filterValues: updatedUnitFilterValues
          };

          if (!isEqual(state.selectedFilters, updatedSelectedUnitFilters)) {
            nextStateOnUpdateUnitFilters.selectedFilters =
              updatedSelectedUnitFilters;
            nextStateOnUpdateUnitFilters.availableFilters =
              state.availableFilters.filter(
                (filter) => filter.key !== action.payload.filterKey
              );
          }

          //persistStateInLocalStorage(nextStateOnUpdateUnitFilters);
          return nextStateOnUpdateUnitFilters;

        case 'onClearFocus':
          const onClearFocusFilters = state.selectedFilters.map((filter) => {
            return {
              ...filter,
              focused: false
            };
          });

          const stateOnClearFocusFilter = {
            ...state,
            selectedFilters: onClearFocusFilters
          };

          //persistStateInLocalStorage(stateOnClearFocusFilter);
          return stateOnClearFocusFilter;

        case 'setVaultFilter':
          return {
            ...state,
            vaultFilter: action.payload
          };

        case 'storeUserPreferenceList': {
          return {
            ...state,
            userSearchPreferences: action.payload
          };
        }

        case 'setActiveUserSearchPreference': {
          return { ...state, activeUserSearchPreference: action.payload };
        }
        default:
          return state;
      }
    },
    {
      allFilters: filtersConfig.allFilters,
      availableFilters: filtersConfig.allFilters,
      selectedFilters: [],
      filterValues: getFilters(),
      temporaryFilterValues: getFilters(),
      currentWizardStep: 0,
      userSearchPreferences: null,
      activeUserSearchPreference: null,
      // hide wizard by default
      wizardCompleted: true
    }
  );

  return (
    <ProjectsFiltersContext.Provider
      value={{ ProjectsFiltersState, ProjectsFiltersDispatch }}
    >
      {props.children}
    </ProjectsFiltersContext.Provider>
  );
};

export const useProjectsFiltersState = () => useContext(ProjectsFiltersContext);

export default ProjectsFiltersStore;
