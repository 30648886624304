// Components
import UnitPreview from './UnitPreview';
import UnitGeneralFields from './UnitGeneralFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthState } from 'src/store/AuthStore';

// Helpers
import {
  getColorForUnitState,
  getFieldIcon,
  getUnitPrice,
  getLocalizedTextForUnitState
} from './unitListHelpers';
import currencySymbolMap from 'currency-symbol-map';
import { isMobile, isMobileOnly } from 'react-device-detect';
import localizer, { getCurrentLanguage } from 'src/localization/localizer';
import { capitalize, fetchSettingsFromURL } from 'src/helpers/utils';
import { forBiggerThan } from 'src/helpers/ui';
import { useContactUsState } from 'src/store/ContactUsStore';
import { useParams } from 'react-router-dom';

// Styling
import styled, { StyledProps } from 'styled-components';
import { motion } from 'framer-motion';
import { GeneralFieldType } from '@/libs/prompto-api/src';
import { checkUnitFieldsVisibility } from 'src/helpers/vmUnit';

const Wrapper = styled(motion.div)<StyledProps<{ status: string }>>`
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 16px 16px 0;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.gray300};
  background-color: ${({ theme }) => theme.white};
  display: grid;
  grid-template-columns: ${isMobileOnly ? 95 : 120}px 1fr;
  grid-template-rows: ${isMobileOnly ? 22 : 35}px ${isMobileOnly ? 21 : 33}px 22px 55px;
  grid-template-areas: 'img title' 'img type' 'img price' 'details details';
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-bottom: 24px;

  ${forBiggerThan.desktop`
    height: 104px;
    padding: 12px 32px;
    box-sizing: border-box;
    border-radius: 4px;
    grid-template-columns: 80px minmax(auto, 20%) minmax(100px, auto) minmax(50px, 160px)  1px minmax(180px, 0.75fr) minmax(105px,200px) 40px 120px;
    grid-template-rows: 1fr;
    grid-template-areas: 'img title type price divider details status favourite more';
    align-items: center;
    gap: 18px;
    margin-bottom: 16px;
  `}

  ${forBiggerThan.largeDesktop`
    grid-template-columns: 80px minmax(5%, max-content) minmax(15%, max-content) minmax(1%, max-content) 11px minmax(15%, 1fr) minmax(15%,1fr) 40px 120px;
  `}
`;

const MoreInfoButton = styled.div`
  width: 120px;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.gray20};
  border-radius: 4px;
  color: ${({ theme }) => theme.gray50};
  font-size: 0.875rem;
  font-weight: 600;
  display: none;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  grid-area: more;
  text-align: center;

  ${forBiggerThan.desktop`
    display: flex;
  `}
`;

// const FavoriteButton = styled(MoreInfoButton)`
//   width: 40px;
// `;

const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.black};
  grid-area: title;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Divider = styled.div`
  height: 20px;
  width: 1px;
  margin: 0;
  background: ${({ theme }) => theme.gray20};
  display: none;
  grid-area: divider;

  ${forBiggerThan.desktop`
    display: block;
  `}
`;

const Status = styled.div<StyledProps<{ status: string }>>`
  width: fit-content;
  min-width: 96px;
  height: 28px;
  border-radius: 14px;
  color: ${({ theme }) => theme.white};
  font-size: 0.75rem;
  font-weight: bold;
  padding: 1px 5px;
  background: ${({ theme, status }) => getColorForUnitState(theme, status)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -14px;
  right: 14px;
  grid-area: status;

  ${forBiggerThan.desktop`
    width: auto;
    min-width: auto;
    position: static;
  `}
`;

const UnitType = styled.div`
  height: 20px;
  font-size: 14px;
  color: ${(props) => props.theme.showcaseBlack};
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  grid-area: type;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  margin-right: 12px;
  color: ${({ theme }) => theme.gray50};
`;

const UnitPrice = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  white-space: nowrap;
  box-sizing: border-box;
  word-break: break-all;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const Price = styled.div`
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-area: price;
`;

const RequestPriceButton = styled.div`
  border: 1px solid ${({ theme }) => theme.gray20};
  border-radius: 24px;
  background-color: ${({ theme }) => theme.beigeBg20};
  padding: 4px 8px;
  color: ${({ theme }) => theme.black};
  font-weight: normal;
  font-size: 0.875rem;
  align-items: center;
  justify-content: center;
  display: flex;
  max-height: 16px;
  white-space: normal;
  line-height: 1.1;

  p {
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    word-break: normal;
  }

  ${forBiggerThan.tablet`
    padding: 10px;
  `}
`;

const RequestPriceIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.black};
  margin-left: 8px;
`;
const FavouriteButton = styled.div`
  display: flex;
  position: absolute;
  top: 25px;
  right: 15px;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: solid 1px #e3e5e8;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.black};
  font-size: 0.875rem;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  background: ${({ theme }) => theme.white};
  cursor: pointer;
  z-index: 5;

  ${forBiggerThan.tablet`
  grid-area: favourite;
  position:relative;
  right: 0;
  top: 0;
  `}
`;

const FavouriteIcon = styled(FontAwesomeIcon)`
  width: 20px !important;
  height: 20px !important;
  color: black;
`;
interface UnitCardProps {
  onCardClick: (unitObjectId: string) => void;
  unit: any;
  showcaseConfiguration: any;
  hidePrices: boolean;
}

const UnitCard = (props: UnitCardProps) => {
  const { unit, onCardClick, showcaseConfiguration, hidePrices } = props;

  const { AuthState, AuthStateDispatch } = useAuthState();
  const { favouriteUnits } = AuthState;

  const { disableAccount } = fetchSettingsFromURL();

  /**
   * START of logic to hide prices based on url param and to show contact us form
   */

  const { unitId } = useParams();
  const { ContactUsStateDispatch } = useContactUsState();

  const requestUnitPrice = () => {
    ContactUsStateDispatch({
      type: 'setShowContactUsForm',
      payload: {
        showContactUsForm: true,
        unitObjectId: unitId,
        location: unitId ? 'unitPageUnitCardPrice' : 'projectPageUnitCardPrice'
      }
    });
  };

  const requestUnitPriceButton = (
    <RequestPriceButton
      title={localizer.unitList.priceOnRequest}
      onClick={(e) => {
        e.stopPropagation();
        requestUnitPrice();
      }}
    >
      <p>{localizer.unitList.priceOnRequest}</p>
      <RequestPriceIcon icon={['far', 'arrow-right']} size="1x" />
    </RequestPriceButton>
  );
  /**
   * END of logic to hide prices
   */

  // unit price
  const currencySymbol = currencySymbolMap(
    unit?.unitPrice?.currencyCode ?? 'EUR'
  );
  const unitPrice = `${currencySymbol} ${getUnitPrice(unit)}`;
  const unitPriceTitle = hidePrices ? '' : unitPrice;

  const [showPrice] = checkUnitFieldsVisibility(unit, [GeneralFieldType.price]);

  const unitPriceComponent =
    unit.state === 'SOLD' || !showPrice ? (
      <div />
    ) : (
      <UnitPrice title={unitPriceTitle}>
        {hidePrices ? (
          requestUnitPriceButton
        ) : (
          <>
            <StyledIcon
              icon={getFieldIcon(showcaseConfiguration, 'price', 'far')}
              size="1x"
            />
            <Price>{unitPrice}</Price>
          </>
        )}
      </UnitPrice>
    );

  let unitTitle = unit.localizedTitle?.textMap?.[getCurrentLanguage()];
  if (!unitTitle || unitTitle === '')
    unitTitle = unit.localizedTitle?.textMap?.['en'];
  if (!unitTitle || unitTitle === '') unitTitle = unit.title;

  let unitType = '';
  if (unit.unitType)
    unitType = unit.unitType.name?.textMap?.[getCurrentLanguage()];
  if (unit.unitType && unitType === '')
    unitType = unit.unitType.name?.textMap?.['en'];

  return (
    <Wrapper
      onClick={() => onCardClick(unit.objectId)}
      whileHover={{ scale: 1.01 }}
      status={unit.state}
    >
      <UnitPreview unit={unit} height={isMobile && !isMobileOnly ? 95 : 80} />

      <Title title={unitTitle}>{unitTitle}</Title>

      <UnitType title={unitType}>
        {unit?.unitType?.icon && (
          <StyledIcon icon={['far', unit.unitType.icon]} size="1x" />
        )}
        {unitType}
      </UnitType>

      {unitPriceComponent}

      <Divider />

      <UnitGeneralFields
        unit={unit}
        showcaseConfiguration={showcaseConfiguration}
      />

      <Status status={unit.state}>
        {getLocalizedTextForUnitState(unit?.state, unit?.unitFlow)}
      </Status>

      {!disableAccount && (
        <FavouriteButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            AuthStateDispatch({
              type: 'toggleFavouriteUnit',
              payload: {
                unitId: unit.objectId,
                vaultId: unit.vault.objectId,
                projectId: unit.parentProjectSection.objectId
              }
            });
          }}
        >
          <FavouriteIcon
            icon={[
              favouriteUnits?.find(
                (x: any) => x.favoriteUnitId === unit.objectId
              )
                ? 'fas'
                : 'far',
              'heart'
            ]}
            size="sm"
          />
        </FavouriteButton>
      )}
      <MoreInfoButton>
        {capitalize(localizer.unitListComponent.moreInfo)}
      </MoreInfoButton>
    </Wrapper>
  );
};

export default UnitCard;
